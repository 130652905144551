import { Survey, SurveyReply, SurveyQuestionAnswerQuestion as Question } from '../types/Survey';

const getQuestion = (step: number, survey: Survey) => {
  if (survey.questions) {
    return survey.questions[step];
  }
  return undefined;
};

const getAnswer = (questionId: number, answers: SurveyReply[]) => {
  let returnId;

  if (answers) {
    const answer = answers.find((a) => a.questionId === questionId);

    if (answer && !answer.skipped) {
      returnId = answer.answer;
    }
  }

  return returnId;
};

const hasInformalAnswerEnabled = (step: number, survey: Survey) => {
  if (survey.questions[step]?.allowInformal === 1) {
    return true;
  }

  return false;
};

const getInformalAnswer = (questionId: number, answers: SurveyReply[]) => {
  let informalAnswer;

  if (answers) {
    const answer = answers.find((a) => a.questionId === questionId);

    if (answer && !answer.skipped) {
      informalAnswer = answer.informalAnswer || undefined;
    }
  }

  return informalAnswer;
};

const getTotalSteps = (questions: Question[]) => {
  return questions.length;
};

const getAnswerForStep = (stepIndex: number, survey: Survey, answers: SurveyReply[]) => {
  const question = getQuestion(stepIndex, survey);
  let answer;

  if (question) {
    answer = getAnswer(question?.id, answers);
  }

  return answer;
};

export default {
  getQuestion,
  getAnswer,
  getTotalSteps,
  getAnswerForStep,
  getInformalAnswer,
  hasInformalAnswerEnabled,
};
