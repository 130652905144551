import React from 'react';
import {
  SurveyQuestionAnswerQuestion as SurveyQuestion,
  SurveyReply,
} from './types/Survey';
import AnsweringHelper from './helper/AnsweringHelper';
import QuestionDescription from './QuestionDescription';
import Smileys from './QuestionTypes/Smileys';
import Select from './QuestionTypes/Select';

interface QuestionProps {
  question: SurveyQuestion | undefined;
  answers: SurveyReply[];
  hasInformalQuestion: boolean;
  handleSelect: Function;
}

const Question = ({
  question,
  handleSelect,
  answers,
  hasInformalQuestion,
}: QuestionProps) => {
  if (question && question.id) {
    switch (question ? question.configuration.type : '') {
      case 'smileys':
        return (
          <>
            <QuestionDescription question={question} />
            <Smileys
              questionId={question.id}
              answerId={AnsweringHelper.getAnswer(question.id, answers)}
              data={question.configuration.options}
              handleSelect={handleSelect}
              hasInformalQuestion={hasInformalQuestion}
              informalAnswer={AnsweringHelper.getInformalAnswer(
                question.id,
                answers
              )}
            />
          </>
        );
      case 'select':
        return (
          <>
            <QuestionDescription question={question} />
            <Select
              questionId={question.id}
              data={question.configuration.options}
              answerId={AnsweringHelper.getAnswer(question.id, answers)}
              handleSelect={handleSelect}
              hasInformalQuestion={hasInformalQuestion}
              informalAnswer={AnsweringHelper.getInformalAnswer(
                question.id,
                answers
              )}
            />
          </>
        );
      case 'requestContact':
        return (
          <>
            <QuestionDescription question={question} />
            <Select
              questionId={question.id}
              data={question.configuration.options}
              answerId={AnsweringHelper.getAnswer(question.id, answers)}
              handleSelect={handleSelect}
              hasInformalQuestion={hasInformalQuestion}
              informalAnswer={AnsweringHelper.getInformalAnswer(
                question.id,
                answers
              )}
            />
          </>
        );
      default:
        return <div>ok</div>;
    }
  }
  return <div>ok</div>;
};

export default Question;
