import React from 'react';
import { hydrate, render } from 'react-dom';

import App from './App';

import './tailwind.css';

const root = document.getElementById('root');
if (root?.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root
  );
} else {
  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    root
  );
}
