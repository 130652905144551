import axios, { AxiosResponse } from 'axios';

interface ApiConfigurationParameters {
  SURVEY_API_URL: string;
}

interface MetaDataResponse {
  version: string;
}

export default class ApiConfig {
  static version: string = '';

  static isVersionCheckPostponed: boolean = false;

  static config: ApiConfigurationParameters = {
    SURVEY_API_URL: '',
  };

  static fetchConfig() {
    return axios.get('/config/config.json');
  }

  static fetchMetadata() {
    return axios
      .get('/metadata/metadata.json')
      .then((result: AxiosResponse<MetaDataResponse>) => {
        this.version = result.data.version;
        this.isVersionCheckPostponed = false;
      });
  }

  static setMetaDataVersionPostpone(value: boolean) {
    this.isVersionCheckPostponed = value;
  }

  static isRunningCurrentVersion() {
    if (
      process.env.REACT_APP_CONTAINER_IMAGE === 'development' ||
      this.version === 'development'
    ) {
      return true;
    }
    return process.env.REACT_APP_CONTAINER_IMAGE === this.version;
  }

  static setConfig(incomingConfig: ApiConfigurationParameters) {
    this.config = {
      ...this.config,
      ...incomingConfig,
    };
  }

  static getConfig() {
    return this.config;
  }
}
