import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { setConfig } from 'react-hot-loader';
import AnsweringContainer from './AnsweringContainer';
import Footer from './Footer';
import Default from './Default';
import SurveyComponents from './SurveyComponents';
import { Survey } from './types/Survey';

/**
 * Get rid of a HMR console error:
 *
 * React-hot-loader: react-🔥-dom patch is not detected. React 16.6+ features may not work.
 *
 * Source: https://stackoverflow.com/questions/54770535/react-hot-loader-react-dom-patch-is-not-detected
 */
setConfig({
  showReactDomPatchNotification: false,
});

const App = () => {
  const [survey, setSurvey] = React.useState<Survey | null>(null);

  useEffect(() => {
    document.title = 'Asiakaskysely';
  });
  return (
    <Router>
      <Switch>
        {/* TODO: maybe ignore if prod env */}
        <Route path="/survey-components" component={SurveyComponents} />
        <Route
          path={['/:answerType/:hash/:answer?', '//:answerType/:hash/:answer?']}
          // component={AnsweringContainer as any}
          render={(props) => (
            <AnsweringContainer
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              survey={survey}
              setSurvey={setSurvey}
            />
          )}
        />
        <Route render={() => <Default message="404" />} />
      </Switch>
      <Footer survey={survey} />
    </Router>
  );
};

export default hot(App);
