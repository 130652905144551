import React from 'react';
import { SurveyQuestionAnswerQuestionConfigurationDataOptions as Options } from '../types/Survey';
import InformalAnswer from '../InformalAnswer';

interface SmileyProps {
  questionId: number;
  answerId: number | undefined;
  data: Options[];
  informalAnswer: string | undefined;
  hasInformalQuestion: boolean;
  handleSelect: Function;
}

const Smileys = ({
  questionId,
  answerId,
  data,
  informalAnswer,
  hasInformalQuestion,
  handleSelect,
}: SmileyProps) => {
  return (
    <div className="survey-question-container">
      <div className="survey-smiley-container">
        {data &&
          data.map((q) => {
            return (
              <button
                className="survey-smiley-button focus:outline-none focus:shadow-outline"
                data-testid={`smiley-${q.value}`}
                key={q.value}
                type="button"
                onClick={() => handleSelect(questionId, q.value, undefined)}
              >
                <span
                  key={q.value}
                  role="img"
                  aria-label={q.ariaText}
                  className={`survey-smiley-button-content hover:bg-blue-200 rounded-lg ${
                    q.value === answerId
                      ? 'survey-smiley-button-content-selected'
                      : ''
                  }`}
                >
                  {String.fromCodePoint(
                    Number.parseInt(q.text.replace('U+', '0x'), 0)
                  )}
                </span>
              </button>
            );
          })}
      </div>
      <InformalAnswer
        informalAnswer={informalAnswer}
        questionId={questionId}
        answer={answerId}
        handleSelect={handleSelect}
        hasInformalQuestion={hasInformalQuestion}
      />
    </div>
  );
};

export default Smileys;
