import axios from 'axios';
import { SurveyReply } from './types/Survey';
import ApiConfig from './api/ApiConfig';

const getSurvey = async (hash: string, predefinedSurvey: boolean) => {
  const config = await ApiConfig.fetchConfig();
  ApiConfig.setConfig(config.data);

  const backendUrl = `${config.data.SURVEY_API_URL.replace(/\/$/, '')}/surveys`;

  let response;
  if (predefinedSurvey) {
    response = await axios.get(`${backendUrl}/answer/${hash}`);
  } else {
    response = await axios.get(`${backendUrl}/${hash}`);
  }

  return response;
};

const postReply = async (surveyReplies: SurveyReply[], hash: string) => {
  // surveys/:hash/answer
  const backendUrl = `${ApiConfig.getConfig().SURVEY_API_URL.replace(
    /\/$/,
    ''
  )}/surveys/answer/${hash}`;

  const data: object = { questions: surveyReplies };
  try {
    await axios({
      method: 'post',
      url: backendUrl,
      data,
    });

    return true;
  } catch {
    return false;
  }
};

export default { getSurvey, postReply };
