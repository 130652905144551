import React from 'react';
import { Helmet } from 'react-helmet';

import Answering from './Answering';
import Default from './Default';
import SurveyRequest from './SurveyRequest';
import { Survey } from './types/Survey';

interface AnsweringProps {
  setSurvey: (survey: Survey) => void;
  survey: Survey | null;
}

interface AnsweringState {
  status: 'loading' | 'error';
  hash: string;
  predefinedSurvey: boolean;
  predefinedAnswer: number | undefined;
  isSurveyAnswered: boolean;
}

class AnsweringContainer extends React.Component<
  AnsweringProps,
  AnsweringState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      status: 'loading',
      isSurveyAnswered: false,
      hash: props.match.params.hash,
      // If answerType is a (Answer), get Survey from SurveyAnswer. Otherwise use Survey hash
      // by assigning predefinedSurvey as false
      predefinedSurvey: props.match.params.answerType === 'a' || false,
      predefinedAnswer: props.match.params.answer
        ? parseInt(props.match.params.answer, 10)
        : undefined,
    };
  }

  render() {
    const { survey, setSurvey } = this.props;
    const {
      predefinedSurvey,
      hash,
      predefinedAnswer,
      status,
      isSurveyAnswered,
    } = this.state;

    if (survey) {
      return (
        <>
          <Helmet>
            <title>{survey.title}</title>
            <style type="text/css">{survey.customCss}</style>
          </Helmet>
          <Answering
            survey={survey}
            answerHash={hash}
            predefinedAnswer={predefinedAnswer}
            isSurveyAnswered={isSurveyAnswered}
          />
        </>
      );
    }

    if (status !== 'error') {
      SurveyRequest.getSurvey(hash, predefinedSurvey)
        .then((data) => {
          const surveyResponse: any = data.data;
          setSurvey(surveyResponse);
          this.setState({ isSurveyAnswered: surveyResponse.isAlreadyAnswered });
        })
        .catch(() => {
          this.setState({ status: 'error' });
        });
    }

    if (status === 'loading') {
      return (
        <div className="loader">
          <div className="sk-fading-circle">
            <div className="sk-circle1 sk-circle" />
            <div className="sk-circle2 sk-circle" />
            <div className="sk-circle3 sk-circle" />
            <div className="sk-circle4 sk-circle" />
            <div className="sk-circle5 sk-circle" />
            <div className="sk-circle6 sk-circle" />
            <div className="sk-circle7 sk-circle" />
            <div className="sk-circle8 sk-circle" />
            <div className="sk-circle9 sk-circle" />
            <div className="sk-circle10 sk-circle" />
            <div className="sk-circle11 sk-circle" />
            <div className="sk-circle12 sk-circle" />
          </div>
        </div>
      );
    }

    return <Default message="Virhe kyselyn latauksessa" />;
  }
}

export default AnsweringContainer;
