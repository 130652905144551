import React from 'react';
import Title from './Title';
import Description from './Description';
import ProgressBar from './ProgressBar';
import SurveyActions from './SurveyActions';
import Question from './Question';

const SMILEY_QUESTION_MOCK = {
  id: 1,
  surveyId: 1,
  title: 'Survey smileys question title',
  description: 'Survey question description',
  type: 'default',
  configuration: {
    type: 'smileys',
    options: [
      { _key: 1, text: 'U+1F641', value: 1, ariaText: '' },
      { _key: 2, text: 'U+1F615', value: 2, ariaText: '' },
      { _key: 3, text: 'U+1F610', value: 3, ariaText: '' },
      { _key: 4, text: 'U+1F642', value: 4, ariaText: '' },
      { _key: 5, text: 'U+1F60A', value: 5, ariaText: '' },
    ],
  },
  allowInformal: 0,
};

const OPTIONS_QUESTION_MOCK = {
  id: 2,
  surveyId: 2,
  title: 'Survey options question title',
  description: 'Survey options question description',
  type: 'default',
  configuration: {
    type: 'select',
    options: [
      { _key: 1, text: 'Option 1', value: 1, ariaText: '' },
      { _key: 2, text: 'Option 2', value: 2, ariaText: '' },
    ],
  },
  allowInformal: 1,
};
const SURVEY_MOCK = {
  id: 1,
  hash: '',
  type: '',
  internalName: '',
  title: 'Survey',
  description: 'Survey description',
  createdAt: 1,
  updatedAt: 1,
  active: true,
  templateId: 1,
  startsAt: 1,
  endsAt: 1,
  completionTitle: '',
  completionMessage: '',
  questions: [SMILEY_QUESTION_MOCK, OPTIONS_QUESTION_MOCK],
  messageLimit: 0,
  customCss: '',
  customLogo: '',
};

const SURVEY_FILLED_ANSWERS_MOCK = [
  {
    answer: 1,
    informalAnswer: undefined,
    questionId: 1,
  },
  {
    answer: 1,
    informalAnswer: undefined,
    questionId: 2,
  },
];
/**
 * Used in cs-frontend, Grapes survey styles editor
 */
const SurveyComponents = () => (
  <div id="survey-answering-container" data-gjs-propagate={'["copyable","editable","draggable","removable","resizable","droppable"]'} data-gjs-draggable="false" data-gjs-removable="false" data-gjs-resizable="false" data-gjs-editable="false" data-gjs-droppable="false" data-gjs-copyable="false" className="survey-answering-container">
    <Title title="Survey title" />
    <ProgressBar step={0} totalSteps={1} />
    <Description onLandingPage description="Survey description" />
    <div data-gjs-id="survey-questions-wrapper" className="survey-questions-wrapper">
      <div data-gjs-id="survey-question-smileys">
        <Question
          question={SMILEY_QUESTION_MOCK}
          answers={SURVEY_FILLED_ANSWERS_MOCK}
          handleSelect={() => {}}
          hasInformalQuestion={false}
        />
      </div>
      <div data-gjs-id="survey-question-options">
        <Question
          question={OPTIONS_QUESTION_MOCK}
          // TODO: is this needed? maybe need to show how it looks with answers sometimes?
          answers={SURVEY_FILLED_ANSWERS_MOCK}
          handleSelect={() => {}}
          hasInformalQuestion
        />
      </div>
    </div>
    <SurveyActions
      survey={SURVEY_MOCK}
      stepIndex={0}
      answers={[]}
      handleNextQuestion={() => {}}
      handlePreviousQuestion={() => {}}
      handleSkipQuestion={() => {}}
      questionId={undefined}
      isQuestionSkippable={false}
    />
    <hr />
    <SurveyActions
      survey={SURVEY_MOCK}
      stepIndex={1}
      answers={SURVEY_FILLED_ANSWERS_MOCK}
      handleNextQuestion={() => {}}
      handlePreviousQuestion={() => {}}
      handleSkipQuestion={() => {}}
      questionId={1}
      isQuestionSkippable
    />
  </div>
);

export default SurveyComponents;
