import React from 'react';
import AnsweringHelper from './helper/AnsweringHelper';
import { Survey, SurveyReply } from './types/Survey';

interface SurveyActionsProps {
  survey: Survey;
  stepIndex: number;
  answers: SurveyReply[];
  isQuestionSkippable: boolean;
  questionId?: number;
  handleNextQuestion: () => void;
  handlePreviousQuestion: () => void;
  handleSkipQuestion: (questionId: number) => void;
}

const SurveyActions = ({
  stepIndex,
  survey,
  answers,
  handleNextQuestion,
  handlePreviousQuestion,
  handleSkipQuestion,
  isQuestionSkippable,
  questionId
}: SurveyActionsProps) => (
  <div className="survey-actions">
    <button
      className={`survey-action-previous hover:bg-green-500 hover:text-white ${
        stepIndex === 0 ? 'hidden' : ''
      }`}
      type="button"
      onClick={() => handlePreviousQuestion()}
    >
      Edellinen
    </button>
    <button
      data-testid="next"
      disabled={
        AnsweringHelper.getAnswerForStep(stepIndex, survey, answers) ===
        undefined
      }
      className={`survey-action-next focus:outline-none focus:shadow-outline focus:bg-green-700
        ${
          AnsweringHelper.getAnswerForStep(stepIndex, survey, answers) !==
          undefined
            ? 'hover:bg-green-900'
            : 'disabled hover:bg-gray-400'
        }`}
      type="button"
      onClick={() => handleNextQuestion()}
    >
      Seuraava
    </button>
    {isQuestionSkippable && (
      <button
        data-testid="skip"
        className="survey-action-skip focus:outline-none focus:shadow-outline focus:bg-green-700 hover:bg-green-900"
        type="button"
        onClick={() => questionId && handleSkipQuestion(questionId)}
      >
        Ohita
      </button>
    )}
  </div>
);

export default SurveyActions;
