import React from 'react';
import { SurveyQuestionAnswerQuestion as SurveyQuestion } from './types/Survey';

interface QuestionDescriptionProps {
  question: SurveyQuestion;
}

const QuestionDescription = ({ question }: QuestionDescriptionProps) => {
  return (
    <div>
      <p className="survey-question-title">{question.title}</p>
      <div
        className="survey-question-description"
        dangerouslySetInnerHTML={{ __html: question.description ?? '' }}
      />
    </div>
  );
};

export default QuestionDescription;
