import React from 'react';
import { hyphenateSync } from 'hyphen/fi';

interface TitleProps {
  title: string;
}
const Title = ({ title }: TitleProps) => (
  <h1 data-gjs-id="survey-title" className="survey-title">
    {hyphenateSync(title)}
  </h1>
);

export default Title;
