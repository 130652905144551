import React from 'react';
import Lottie from 'lottie-react';
import astronautAnimation from './Astronaut/astronaout.json';

interface DefaultProps {
  message: string;
}

const Default = (props: DefaultProps) => {
  return (
    <div className="animation">
      <p className="message">{props.message}</p>
      <Lottie width="30%" animationData={astronautAnimation} />
    </div>
  );
};

export default Default;
