import React from 'react';

interface ProgressBarProps {
  step: number;
  totalSteps: number;
}

const countProgress = (step: number, totalSteps: number) => {
  return `${((step + 1) / totalSteps) * 100}%`;
};

const ProgressBar = ({ step, totalSteps }: ProgressBarProps) => {
  return (
    <div
      data-gjs-id="survey-progress-container"
      className="survey-progress-container"
    >
      <div
        className="survey-progress-bar"
        data-gjs-id="survey-progress-bar"
        style={{ width: countProgress(step, totalSteps) }}
      >
        <span className="survey-progress-bar-content">
          {step + 1} / {totalSteps}
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
