import React from 'react';

interface DescriptionProps {
  onLandingPage: boolean;
  description: string;
}

const Description = ({ onLandingPage, description }: DescriptionProps) => {
  return (
    <div
      data-gjs-id="survey-description"
      style={{ display: onLandingPage ? 'block' : 'none' }}
      className="survey-description"
      dangerouslySetInnerHTML={{ __html: description }}
    />
  );
};

export default Description;
